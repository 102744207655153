/* Button */

	.add-item {
		background-color: _palette(bg);
		width: 30%;
		> span {
			color: _palette(fg-bold);
			font-family: _font(family);
		}
		@include breakpoint(medium) {
			width: 100%;
		}
	}

	.button-gallery {
		border: 0;
		font-weight: -font(weight);
		font-family: _font(family);
		color: _palette(bg);
		background-color: transparent;
		padding-top: 1.5em;
		margin-left: 10em;
	}

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', (
			'border-color #{_duration(transition)} ease'
		));
		background-color: _palette(bg);
		border: solid 1.1px;
		color: _palette(fg-bold);
		cursor: pointer;
		display: inline-block;
		font-size: 0.8em;
		font-weight: _font(weight-bold);
		line-height: 1.75em;
		outline: 0;
		padding: 0 .75em;
		position: relative;
		text-align: center;
		text-decoration: none;
		white-space: nowrap;
		pointer-events: visible;
		// &:after {
		// 	@include vendor('transform', 'scale(0.25)');
		// 	@include vendor('pointer-events', 'none');
		// 	@include vendor('transition', (
		// 		'opacity #{_duration(transition)} ease',
		// 		'transform #{_duration(transition)} ease'
		// 	));
		// 	background: _palette(fg-bold);
		// 	border-radius:2.2em;
		// 	content: '';
		// 	height: 100%;
		// 	left: 0;
		// 	opacity: 0;
		// 	position: absolute;
		// 	top: 0;
		// 	width: 100%;
		// }

		// &.icon {
		// 	&:before {
		// 		margin-right: 0.75em;
		// 	}
		// }

		// &.fit {
		// 	display: block;
		// 	margin: 0 0 (_size(element-margin) * 0.5) 0;
		// 	width: 100%;
		// }

		// &.small {
		// 	font-size: 0.4em;
		// }

		// &.big {
		// 	font-size: 0.8em;
		// }

		// &.special {
		// 	background-color: _palette(fg-bold);
		// 	color: _palette(bg) !important;

		// 	&:after {
		// 		display: none;
		// 	}
		// }

		// &.disabled,
		// &:disabled {
		// 	cursor: default;
		// 	opacity: 0.5;
		// 	@include vendor('pointer-events', 'none');
		// }

		// &:hover {
		// 	border-color: _palette(fg) !important;

		// 	&:after {
		// 		opacity: 0.05;
		// 		@include vendor('transform', 'scale(1)');
		// 	}

		// 	&:active {
		// 		border-color: _palette(fg-bold) !important;

		// 		&:after {
		// 			opacity: 0.1;
		// 		}
		// 	}
		// }
	}