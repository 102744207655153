/* Header */

	/* Hamburger menu for mobile */
	
	.fat-nav__wrapper{
		display: none;
		@include breakpoint(medium){
			width:100%;
			height:80%;
			margin-top: 5em;
			display:table;
			table-layout:fixed;
		}
	}
	
	@include breakpoint(medium){

		.fat-nav, .fat-nav-white, .hamburger__icon, .hamburger__icon:after, .hamburger__icon:before {
			-moz-transition-duration:.1s;
			-o-transition-duration:.1s;
			-webkit-transition-duration:.1s;
		}

		//WHITE LINES BURGER
		.hamburger {
			display:block;
			width: 4em;
			height: 4em;
			position:fixed;
			top: .45em;
			right: -5px;
			z-index:10000;
			padding-top: 10px;
			padding-left: 1em;
			-moz-box-sizing:border-box;
			-webkit-box-sizing:border-box;
			box-sizing:border-box;
			-webkit-tap-highlight-color:transparent;
			-ms-touch-action:manipulation;
			touch-action:manipulation;
			border: 0;
		}

		.hamburger__icon {
			position:relative;
			margin-top: 7px;
			margin-bottom: 7px;
		}
		.hamburger__icon,.hamburger__icon:after,.hamburger__icon:before {
			display: block;
			width: 22px;
			height: 2.4px;
			background-color: _palette(fg-bold);
			moz-transition-property:background-color,-moz-transform;
			-o-transition-property:background-color,-o-transform;
			-webkit-transition-property:background-color,-webkit-transform;
			transition-property:background-color,transform;
			transition-duration:.2s;
		}
		.hamburger__icon:after,.hamburger__icon:before {
			position:absolute;
			content:"";
		}
		.hamburger__icon:before {
			top: -7px;
		}
		.hamburger__icon:after {
			top: 7px;
		}
		.hamburger.active .hamburger__icon {
			background-color:transparent;
		}
		.hamburger.active .hamburger__icon:after,.hamburger.active .hamburger__icon:before {
			background-color: _palette(fg-bold);
		}
		.hamburger.active .hamburger__icon:before {
			-moz-transform:translateY(7px)rotate(55deg);
			-ms-transform:translateY(7px)rotate(55deg);
			-webkit-transform:translateY(7px)rotate(55deg);
			transform:translateY(7px)rotate(55deg);
			height: 2.6px;
		}
		.hamburger.active .hamburger__icon:after {
			-moz-transform:translateY(-7px)rotate(-55deg);
			-ms-transform:translateY(-7px)rotate(-55deg);
			-webkit-transform:translateY(-7px)rotate(-55deg);
			transform:translateY(-7px)rotate(-55deg);
			height: 2.6px;
		}

		//BLACK LINES BURGER
		.hamburger-black {
			display:block;
			width: 4em;
			height: 4em;
			position:fixed;
			top: .45em;
			right: -5px;
			z-index:10000;
			padding-top: 10px;
			padding-left: 1em;
			-moz-box-sizing:border-box;
			-webkit-box-sizing:border-box;
			box-sizing:border-box;
			-webkit-tap-highlight-color:transparent;
			-ms-touch-action:manipulation;
			touch-action:manipulation;
			border: 0;
		}

		.hamburger__icon-black {
			position:relative;
			margin-top: 7px;
			margin-bottom: 7px;
		}
		.hamburger__icon-black,.hamburger__icon-black:after,.hamburger__icon-black:before {
			display: block;
			width: 22px;
			height: 2.4px;
			background-color: _palette(bg);
			moz-transition-property:background-color,-moz-transform;
			-o-transition-property:background-color,-o-transform;
			-webkit-transition-property:background-color,-webkit-transform;
			transition-property:background-color,transform;
			transition-duration:.2s;
		}
		.hamburger__icon-black:after,.hamburger__icon-black:before {
			position:absolute;
			content:"";
		}
		.hamburger__icon-black:before {
			top: -7px;
		}
		.hamburger__icon-black:after {
			top: 7px;
		}
		.hamburger-black.active .hamburger__icon-black {
			background-color:transparent;
		}
		.hamburger-black.active .hamburger__icon-black:after,.hamburger-black.active .hamburger__icon-black:before {
			background-color: _palette(bg);
		}
		.hamburger-black.active .hamburger__icon-black:before {
			-moz-transform:translateY(7px)rotate(55deg);
			-ms-transform:translateY(7px)rotate(55deg);
			-webkit-transform:translateY(7px)rotate(55deg);
			transform:translateY(7px)rotate(55deg);
			height: 2.6px;
		}
		.hamburger-black.active .hamburger__icon-black:after {
			-moz-transform:translateY(-7px)rotate(-55deg);
			-ms-transform:translateY(-7px)rotate(-55deg);
			-webkit-transform:translateY(-7px)rotate(-55deg);
			transform:translateY(-7px)rotate(-55deg);
			height: 2.6px;
		}

		.fat-nav {
			top:0;
			left:0;
			z-index:9999;
			position:fixed;
			display:none;
			width:100%;
			height:100%;
			-moz-transform:scale(1.4);
			-ms-transform:scale(1.4);
			-webkit-transform:scale(1.4);
			transform:scale(1.4);
			-moz-transition-property:-moz-transform;
			-o-transition-property:-o-transform;
			-webkit-transition-property:-webkit-transform;
			transition-property:transform;
			transition-duration:.2s;
			overflow-y:auto;
			overflow-x:hidden;
			-webkit-overflow-scrolling:touch;
		}
		.fat-nav-black {
			background:_palette(bg);
		}
		.fat-nav-white {
			background:_palette(fg-bold);
		}
		.fat-nav-gray {
			background:_palette(accent4);
		}
		.fat-nav.active {
			-moz-transform:scale(1);
			-ms-transform:scale(1);
			-webkit-transform:scale(1);
			transform:scale(1);
		}
		.fat-nav ul {
			display:table-cell;
			vertical-align:middle;
			margin:0;
			padding:0;
		}
		.fat-nav li {
			list-style-type: none;
			text-align:center;
			padding: 0;
			font-weight: _font(weight-bold);
			font-size: 1.5em;
		}
		.fat-nav li,.fat-nav li a {
			color: _palette(fg-bold);
		}

		.fat-nav-white li,.fat-nav-white li a, .fat-nav-gray li,.fat-nav-gray li a  {
			color: _palette(bg);
		}

		.fat-nav li a{
			text-decoration:none;
			border:0;
		}
	}


	.dropdown {
		> li {
			list-style: none;
			margin-left: -1em;
			padding: 0;
			> a {
			border: 0;
			font-size: 1.10em;
			font-weight: _font(weight-bold);
			}
		}
	}



	.dropdown-white {
		> li {
			list-style: none;
			margin-left: -1em;
			padding: 0;
			> a {
			border: 0;
			font-size: 1.10em;
			color: _palette(bg);
			font-weight: _font(weight-bold);
			}
		}
	}

	.dropdown-gray {
		> li {
			list-style: none;
			margin-left: -1em;
			padding: 0;
			> a {
			border: 0;
			font-size: 1.10em;
			color: _palette(bg);
			font-weight: _font(weight-bold);
			}
		}
	}

	.mobile-cart {
		visibility: hidden;
		@include breakpoint(medium) {
			visibility: visible;
			position: absolute;
			width: 1.3em;
			top: -.75em;
			left: 2em;
		}
	}

	.cart {
		position: absolute;
		width: 1.2em;
		top: 1.57em;
		right: .90em;
		@include breakpoint(medium) {
			top: 1.37em;
			width: 1.4em;
			right: .75em;
		}
	}

	.snipcart-total-items {
		position: absolute;
		font-size: 0.7em;
		top: 2.6em;
		right: 1.85em;
		@include breakpoint(medium) {
			font-size: 0.8em;
			top: 2.2em;
			right: 1.55em;
		}
	}

	#header {
		@include vendor('display', 'flex');
		cursor: default;
		padding: .25em 1em;
		position: fixed;
		background-color: _palette(bg);
		z-index: 1000;
		width: 100%;
		top: 0;
		@include breakpoint(small) {
			justify-content: center;
		}

		> .cart-item {
			color: _palette(bg);
		}

		> .title {
			border: 0;
			color: _palette(fg-bold);
			padding-top: .25em;
			padding-left: .5em;
			display: block;
			font-size: 1.95em;
			font-weight: _font(weight);
			letter-spacing: 0.1em;
		}

		> #main-nav ul {
		  list-style: none;
		  margin: 0;
		  padding: 0;
		}

		> nav {
			@include vendor('flex', '1');
			text-align: right;
			padding-right: 1em;
			// padding-top: 0.2em;
			// margin-top: -0.6em;
			
			> ul {
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					letter-spacing: 0;
					margin-left: 1em;
					padding: 0;

					&:first-child {
						margin-left: 0;
					}
					&.current a {
						color: _palette(fg-bold);
						&:hover {
							color: _palette(fg-bold);
						}
					}

					a {
						border: 0;
						color: _palette(fg-bold);
						display: inline-block;
						// padding-top: 1.25em;
						font-size: 1.10em;
						font-weight: _font(weight-bold);

						&:hover {
							color: _palette(fg);
						}
					}
				}
			}
		}

		@include breakpoint(medium) {

			.title {
				padding-top: 0;
				padding-left: 2em;
				font-size: 1.55em;
				letter-spacing: 0.11em;
			}

			> nav {
				display: none;
			}
		}

		@include breakpoint(small) {

			.title {
				padding-left: 0;
			}

			> nav {
				display: none;
			}
		}
	}

	#header_gray {
		@include vendor('display', 'flex');
		cursor: default;
		padding: .25em 1em;
		position: fixed;
		background-color: _palette(accent4);
		z-index: 1000;
		width: 100%;
		top: 0;
		@include breakpoint(small) {
			justify-content: center;
		}

		> .cart-item {
			color: _palette(bg);
		}

		> .title {
			border: 0;
			color: _palette(bg);
			padding-top: .25em;
			padding-left: .5em;
			display: block;
			font-size: 1.95em;
			font-weight: _font(weight);
			letter-spacing: 0.1em;
			@include breakpoint(small) {
				position: relative;
			}
		}

		> #main-nav ul {
		  list-style: none;
		  margin: 0;
		  padding: 0;
		}

		> nav {
			@include vendor('flex', '1');
			text-align: right;
			padding-right: 1em;
			// padding-top: 0.2em;
			// margin-top: -0.6em;
			
			> ul {
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					letter-spacing: 0;
					margin-left: 1em;
					padding: 0;

					&:first-child {
						margin-left: 0;
					}
					&.current a {
						color: _palette(bg);
						&:hover {
							color: _palette(bg);
						}
					}

					a {
						border: 0;
						color: _palette(bg);
						display: inline-block;
						// padding-top: 1.25em;
						font-size: 1.10em;
						font-weight: _font(weight-bold);

						&:hover {
							color: _palette(bg);
						}
					}
				}
			}
		}

		@include breakpoint(medium) {

			.title {
				padding-top: 0;
				padding-left: 2em;
				font-size: 1.55em;
				letter-spacing: 0.11em;
			}

			> nav {
				display: none;
			}
		}

		@include breakpoint(small) {

			.title {
				padding-left: 0;
			}

			> nav {
				display: none;
			}
		}
	}

	.coll-nav {
		margin-top: 1.2em;
	}

	#header_white {
		@include vendor('display', 'flex');
		cursor: default;
		padding: .25em 1em;
		position: fixed;
		background-color: _palette(fg-bold);
		z-index: 1000;
		width: 100%;
		top: 0;
		@include breakpoint(small) {
			justify-content: center;
		}

		> .cart-item {
			color: _palette(fg-bold);
		}

		> .title {
			border: 0;
			color: _palette(bg);
			padding-top: .25em;
			padding-left: .5em;
			display: block;
			font-size: 1.95em;
			font-weight: _font(weight);
			letter-spacing: 0.1em;
			@include breakpoint(small) {
				position: relative;
			}
		}

		> #main-nav ul {
		  list-style: none;
		  margin: 0;
		  padding: 0;
		}

		> nav {
			@include vendor('flex', '1');
			text-align: right;
			padding-right: 1em;
			// padding-top: 0.2em;
			// margin-top: -0.6em;
			
			> ul {
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					letter-spacing: 0;
					margin-left: 1em;
					padding: 0;

					&:first-child {
						margin-left: 0;
					}
					&.current a {
						color: _palette(bg);
						&:hover {
							color: _palette(bg);
						}
					}

					a {
						border: 0;
						color: _palette(bg);
						display: inline-block;
						// padding-top: 1.25em;
						font-size: 1.10em;
						font-weight: _font(weight-bold);

						&:hover {
							color: _palette(bg);
						}

					}
				}
			}
		}

		@include breakpoint(medium) {

			.title {
				padding-top: 0;
				padding-left: 2em;
				font-size: 1.55em;
				letter-spacing: 0.11em;
			}

			> nav {
				display: none;
			}
		}

		@include breakpoint(small) {

			.title {
				padding-left: 0;
			}

			> nav {
				display: none;
			}
		}
	}