/* Wrapper */

	#ex1 {
		border-color: _palette(bg);
		position: fixed;
		max-width: 50%;
		height: 100%;
		overflow-y: scroll;
		top: 250px;
		right: 2%;
		padding-bottom: 250px;
		@include breakpoint(medium) {
			max-width: 95%;
			top: 150px;
			padding-bottom: 150px;
		}
		> .header-tissu {
			position: fixed;
			display: flex;
			justify-content: center;
			flex-flow: row wrap;
			top: 0;
			color: _palette(bg);
			> * {
				flex: 1 100%;
			}
			& a {
				z-index:1000;
				pointer-events: visible;
				font-size: 2em;
				font-weight: 800;
				right: 0;
				
				&:hover {
					color: _palette(bg) ;
				}
			}
			> p {
				max-width: 75%;
				top: 1.2em;
				@include breakpoint(medium) {
					width: 90%;
				}
			}
		}
		> .tissu-data {
			margin-bottom: 10%;
		}
	}

	.blocker{
		position: fixed;
		top:0;
		right:0;
		// bottom:0;
		// left:0;
		width: 55%;
		height: 100%;
		// overflow:auto;
		z-index: 1000;
		// padding:20px;
		box-sizing: border-box;
		border: 2px solid;
		border-bottom: 0;
		border-color: _palette(bg);
		background-color: _palette(fg-bold);
		text-align: center;
		@include breakpoint(medium) {
			width: 100%;
			top: 1%;
		}	
	}
	.blocker:before{
		content:"";
		display:inline-block;
		height:100%;
		vertical-align:middle;
		margin-right:-0.05em
	}
	.blocker.behind{
		background-color:transparent
	}
	.modal{
		display:none;
		// vertical-align:middle;
		position:relative;
		z-index:2;
		// max-width:500px;
		box-sizing:border-box;
		// width:50%;
		background:transparent;
		// padding:15px 30px;
		// -webkit-border-radius:8px;
		// -moz-border-radius:8px;
		// -o-border-radius:8px;
		// -ms-border-radius:8px;
		// border-radius:8px;
		// -webkit-box-shadow:0 0 10px #000;
		// -moz-box-shadow:0 0 10px #000;
		// -o-box-shadow:0 0 10px #000;
		// -ms-box-shadow:0 0 10px #000;
		// box-shadow:0 0 10px #000;
		text-align:left
		> ul {
			list-style-type: none;
		}
	}

	.carousel-wrapper {
		margin-top: -7em;
		margin-left: 7em;
		margin-right: 7em;
		// margin: 20px auto;
		position: relative;
		// border: 10px solid #fff;
		// -webkit-border-radius: 5px;
		// -moz-border-radius: 5px;
		// border-radius: 5px;
		// -webkit-box-shadow: 0 0 2px #999;
		// -moz-box-shadow: 0 0 2px #999;
		// box-shadow: 0 0 2px #999;
		@include breakpoint(medium) {
			margin: 0;
			margin-left: 2em;
			margin-bottom: 2em;
		}
	}
	// .carousel-wrapper .photo-credits {
	// 	position: absolute;
	// 	right: 15px;
	// 	bottom: 0;
	// 	font-size: 13px;
	// 	color: #fff;
	// 	text-shadow: 0 0 1px rgba(0, 0, 0, 0.85);
	// 	opacity: .66;
	// }
	// .carousel-wrapper .photo-credits a {
	// 	color: #fff;
	// }
	/** <a href="https://www.jqueryscript.net/tags.php?/Carousel/">Carousel</a> **/
		
	.carousel {
		border: 0;
		position: relative;
		overflow: hidden;
		// width: 100.063em;
		// height: 156.375em;
		width: 600.5px;
		height: 851px;
		@include breakpoint(large) {
			width: 300.5px;
			height: 551px;
		}
		@include breakpoint(medium) {
			display:none;
		}
	}
	.carousel ul {
		width: 20000em;
		position: absolute;
		list-style: none;
		margin: 0;
		padding: 0;
		@include breakpoint(medium) {
			display:none;
		}
	}
	.carousel li {
		float: left;
		> img {
			width: 30%;
			@include breakpoint(xlarge) {
				width: 25%;
			}
			@include breakpoint(large) {
				width: 15%;
			}
			@include breakpoint(medium) {
				display:none;
			}
		}
	}
	/** Carousel Controls **/
		
	.carousel-control-prev, .carousel-control-next {

		position: absolute;
		border: 0;
		top: 2.5em;
		// width: 30px;
		// height: 30px;
		font-size: 3.75em;
		text-align: center;
		color: _palette(bg);
		text-decoration: none;
		// text-shadow: 0 0 1px #000;
		// font: 24px/27px Arial, sans-serif;
		// -webkit-border-radius: 30px;
		// -moz-border-radius: 30px;
		// border-radius: 30px;
		// -webkit-box-shadow: 0 0 2px #999;
		// -moz-box-shadow: 0 0 2px #999;
		// box-shadow: 0 0 2px #999;
		&:hover {
			color: _palette(bg);
		}
	}
	.carousel-control-prev {
		left: 150px;
		pointer-events: visible;
		@include breakpoint(xlarge) {
			left: 100px;
		}
		@include breakpoint(large) {
			left: 100px;
		}
		@include breakpoint(medium) {
			display:none;
		}
	}
	.carousel-control-next {
		// right: 14em;
		left: 700px;
		pointer-events: visible;
		@include breakpoint(xlarge) {
			left: 600px;
		}
		@include breakpoint(large) {
			left: 415px;
		}
		@include breakpoint(medium) {
			display:none;
		}
	}
	.carousel-control-prev:hover span, .carousel-control-next:hover span {
		display: block;
		@include breakpoint(medium) {
			display:none;
		}
	}

	.wax-scroll {
		@include breakpoint(medium) {
			display:none;
		}
	}

	.mobile-images {
		display: none;
		@include breakpoint(medium) {
			display: initial;
		}
	}

	.wrapper {
		position: relative;
		pointer-events: none;

		@include breakpoint(small) {
			max-width: 100%;
		}

		> .gallery {
			padding: 4em 0.5em 3em 0.8em;
			max-width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			pointer-events: none;

			@include breakpoint(medium) {
				// @include padding(6em, 1em);
				padding: 4em 0.5em 3em 0.8em;
				max-width: 100%;
			}
		}

		> .inner {
			@include padding(5em, 5em);
			max-width: 100%;
			width: _size(inner-width);

			@include breakpoint(xlarge) {
				@include padding(4em, 4em);
			}

			@include breakpoint(large) {
				// max-width: 75%;
				margin-top: 0;
			}

			@include breakpoint(small) {
				@include padding(3em, 2em);
				max-width: 100%;
				margin-left: 0;
			}

			&.about {
				> * {
					flex: 1 100%;
				}
				> p {
					font-size: .85em;
					line-height: 1.5em;
					-webkit-animation: .5s ease 0s normal forwards 1 about;
					animation: .5s ease 0s normal forwards 1 about;
					visibility: hidden;
					// @include breakpoint(large) {
					// 	max-width: 120%;
					// 	font-size: .95em;
					// 	line-height: 1.25em;
					// 	margin: 0;
					// 	padding-top: 3.6em;
					// 	padding-left: 3.9em;
					// 	padding-right: 4.1em;
					// }
					@include breakpoint(medium) {
					// 	max-width: 120%;
					// 	height: 50%;
						font-size: .65em;
					// 	margin: 0;
					// 	padding-top: 1.4em;
					// 	padding-left: 3.9em;
					// 	padding-right: 4.1em;
					}
					@include breakpoint(xsmall) {
						font-size: .55em;
					}
				}
			}
		}

		@keyframes about{
			from { opacity:0; }
			to { opacity:1; visibility: visible; }
		}
		
		@-webkit-keyframes about{
			from { opacity:0; }
			to { opacity:1; visibility: visible; }
		}

		> .brexit {
			padding: 4em 4em 2em 2em;
			max-width: 45%;
			margin-top: 5em;
			@include breakpoint(medium) {
				padding: 1em 4em 0 2em;
			}
			@include breakpoint(small) {
				padding: 1em 4em 0 2em;
				max-width: 100%;
			}
		}

		> .egerie {
			float: right;
			padding: 4em 4em 2em 2em;
			max-width: 56%;
			@include breakpoint(medium) {
				max-width: 100%;
			}
			@include breakpoint(small) {
				max-width: 100%;
			}
		}

		> .wax {
			visibility: hidden;
			@include breakpoint(medium) {
				visibility: visible;
				padding: 0 2em 0 2em;
				margin-left: 0.3em;
				position: relative;
				max-width: 100%;
			}
		}
		> .wax-2 {
			padding: 4em 4em 2em 2em;
			margin-top: 5em;
			// float: right;
			position: fixed;
			right: 0;
			max-width: 45%;
			@include breakpoint(medium) {
				float: none;
				position: relative;
				margin-top: 0;
				max-width: 100%;
			}
		}

		> .article {
			padding: 4em 4em 2em 2em;
			max-width: 25%;
			margin-top: 5em;
			margin-right: 25%;
			float: left;
			@include breakpoint(medium) {
			padding: 0 1em 0 1em;
			margin-top: 0;
			max-width: 100%;
			margin-right: 0;
			float:none;
			}
		}

		> .article-2 {
			padding: 4em 4em 2em 2em;
			margin-top: 5em;
			> h2 {
				font-size: 1.2em;
			}
			> h3 {
				font-size: 1.2em;
				// float: right;
				// right: 0;
				// margin-top: -4%;
				@include breakpoint(medium) {
					float: right;
					margin-top: -3em;
				}
			}
			> .button-gallery {
				@include breakpoint(medium) {
					float: right;
					margin-top: -6em;
				}
			}
			> p {
				font-size: 1em;
			}
			@include breakpoint(medium) {
				// padding: 0 2em 2em 0;
				padding-top: 0;
				padding-right: 1em;
				margin-top: 0;
				margin-left: 0;
				margin-right: 0;
			}
		}
		> .infos {
			margin-top: 10em;
			text-align: left;
			display:flex;
			justify-content: center;
			@include breakpoint(small) {
				margin-top: 5em;
			}
		}

		> .contact {
			font-size: 1.15em;
			margin-top: 150px;
			margin-bottom: 100px;
			text-align: center;
			display: flex;
			justify-content: center;
			flex-flow: row wrap;
			@include breakpoint(small) {
				margin-top: 100px;
			}
			> * {
				flex: 1 100%;
			}
		}
		> .contact-2 {
			margin-bottom: 30px;
			text-align: center;
		}

		> .partners {
			font-size: .75em;
			margin-top: 150px;
			margin-left: 50px;
			margin-bottom: 100px;
			text-align: left;
			display: flex;
			justify-content: center;
			@include breakpoint(small) {
				flex-flow: row wrap;
				margin-top: 100px;
				font-size: 1em;
			}
			> * {
				flex: 1 100%;
			}
		}

		&.alt {
			background-color: _palette(bg-alt);
		}

		&.style1 {
			background-color: _palette(accent1);
		}

		&.style1-alt {
			background-color: _palette(fg-bold);
		}

		&.style2 {
			background-color: _palette(accent2);
		}

		&.style2-alt {
			background-color: _palette(accent2-alt);
		}

		&.style3 {
			background-color: _palette(accent3);
		}

		&.style3-alt {
			background-color: _palette(accent3-alt);
		}

		&.fullscreen {
			@include vendor('display', 'flex');
			@include vendor('flex-direction', 'column');
			@include vendor('justify-content', 'center');
			min-height: 100vh;

			body.is-ie & {
				height: 100vh;
			}

			@include breakpoint(large) {
				min-height: calc(100vh - 2.5em);

				body.is-ie & {
					height: calc(100vh - 2.5em);
				}
			}

			@include breakpoint(small) {
				padding: 0;
				min-height: 0;

				body.is-ie & {
					height: auto;
				}
			}
		}

		&.fade-up {
			> .inner {
				@include vendor('transform', 'translateY(0)');
				@include vendor('transition', (
					'opacity #{_duration(activation)} ease',
					'transform #{_duration(activation)} ease'
				));
				opacity: 1.0;
			}

			&.inactive,
			body.is-loading & {
				> .inner {
					opacity: 0;
					@include vendor('transform', 'translateY(1em)');
				}
			}
		}

		&.fade-down {
			> .inner {
				@include vendor('transform', 'translateY(0)');
				@include vendor('transition', (
					'opacity #{_duration(activation)} ease',
					'transform #{_duration(activation)} ease'
				));
				opacity: 1.0;
			}

			&.inactive,
			body.is-loading & {
				> .inner {
					opacity: 0;
					@include vendor('transform', 'translateY(-1em)');
				}
			}
		}

		&.fade {
			> .inner {
				@include vendor('transition', (
					'opacity #{_duration(activation)} ease'
				));
				opacity: 1.0;
			}

			&.inactive,
			body.is-loading & {
				> .inner {
					opacity: 0;
				}
			}
		}
	}
