/* Footer */

	#footer {
		position: fixed;
		display: flex;
		align-items: baseline;
		width: 100%;
		height: 1.5em;
		bottom: 0;
		pointer-events: visible;

		&.black {
			background-color: _palette('bg');
			
			li {
				border: 0;

				& a {
					border: 0;
					&:hover {
					color: _palette('fg-bold');
					}
				}
			}
		}

		&.gray {
			background-color: _palette('accent4');
		}

		a:hover {
			color: _palette(bg);
		}

		#sidebar + #wrapper + & {
			margin-left: _size(sidebar-width);

			@include breakpoint(large) {
				margin-left: 0;
			}
		}

		> .inner {
			padding: 0;
			margin: 0;
			padding-left: 15px;
			width: 100%;
			@include breakpoint(xsmall) {
				padding-top: 1px;
				padding-left: 5px;
			}

			ul {
				margin: 0 0 1.7em 0;
				> li {
					font-size: 0.7em;
					@include breakpoint(medium) {
						font-size: 0.6em;
					}
					@include breakpoint(small) {
						font-size: 0.4em;
					}
				}
				@include breakpoint(medium) {
					margin: 0;
				}
			}

			li:last-child {
				float: right;
				margin-top: 10px;
				margin-right: 10px;
				@include breakpoint(xsmall) {
					float: none;
					margin-top: 5px;
					margin-right: 5px;
				}
			}

			a {
				border-bottom-color: _palette(border);

				&:hover {
					border-bottom-color: transparent;
				}
			}

			.menu {
				font-size: 0.8em;
				color: _palette(border);
				@include breakpoint(medium) {
					font-size: 0.2em;
				}
			}
		}

		#header + #wrapper + & {
			> .inner {
				margin: 0 auto;
			}
		}
	}

	#footer {
		#sidebar_memo + #wrapper + & {
			margin-left: _size(sidebar-width);

			@include breakpoint(large) {
				margin-left: 0;
			}
		}

		> .inner {
			a {
				border-bottom-color: _palette(border);

				&:hover {
					border-bottom-color: transparent;
				}
			}

			.menu {
				font-size: 0.8em;
				color: _palette(bg);
			}
		}
	}