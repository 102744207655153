@import url('https://fonts.googleapis.com/css?family=Space+Mono:400,400i,700,700i');

// Misc.
	$misc: (
		z-index-base:		10000,
		max-features:		20,
		max-sidebar-links:	20
	);

// Duration.
	$duration: (
		transition:			0.2s,
		activation:			1s
	);

// Size.
	$size: (
		border-radius:		0.25em,
		border-width:		1px,
		element-height:		2.75em,
		element-margin:		2em,
		sidebar-width:		18em,
		sidebar-height:		3.5em,	// when <=large is active
		inner-width:		75em
	);

// Font.
	$font: (
		family:				('Space Mono', garamond),
		family-fixed:		('Courier New', monospace),
		weight:				normal,
		weight-bold:		bold,
		kerning-alt:		0.25em
	);

// Palette.
	$palette: (
		bg:					black,
		bg-alt:				darken(black, 5),
		fg:					rgba(255, 255, 255, 0.808),
		fg-bold:			#ffffff,
		fg-light:			rgba(255, 255, 255, 0.404),
		border:				rgba(255,255,255,0.15),
		border-bg:			rgba(255, 255, 255, 0.295),
		accent1:			black,
		accent1-alt:		darken(black, 10),
		accent2:			black,
		accent2-alt:		darken(black, 10),
		accent3:			black,
		accent3-alt:		darken(black, 10),
		accent4:			#cccccc,
		accent5:			transparent,
	);