/* Sidebar */

	#sidebar {
		@include padding(2.5em, 2.5em);
		background: _palette(accent5);
		cursor: default;
		height: 100vh;
		width: 15em;
		left: 0;
		overflow-x: hidden;
		overflow-y: auto;
		position: fixed;
		text-align: center;
		top: 0;
		width: _size(sidebar-width);
		z-index: _misc(z-index-base);

		> .inner {
			@include vendor('display', 'flex');
			@include vendor('flex-direction', 'column');
			@include vendor('justify-content', 'center');
			@include vendor('transform', 'translateY(0)');
			@include vendor('transition', (
				'opacity #{_duration(activation)} ease',
			));
			min-height: 100%;
			opacity: 1;
			width: 100%;

			body.is-ie & {
				height: 100%;
			}
		}

		nav {
			> ul {
				list-style: none;
				padding: 0;

				> li {
					@include vendor('transform', 'translateY(0)');
					@include vendor('transition', (
						'opacity #{_duration(activation) * 0.15} ease',
						'transform #{_duration(activation) * 0.50} ease'
					));
					margin: 1.5em 0 0 0;
					opacity: 1;
					padding: 0;
					position: relative;

					&:first-child {
						margin: 0;
					}
					&:last-child {
						margin-top: 10em;
						text-transform: uppercase;
						&:hover {
							color: _palette(fg-bold);
						}

					}

					@for $i from 1 through _misc(max-sidebar-links) {
						&:nth-child(#{$i}) {
							@include vendor('transition-delay', '#{(_duration(activation) * 0.2 * $i) + (_duration(activation) * 0.15)}');
						}
					}
				}
			}

			a {
				@include vendor('transition', 'color #{_duration(transition)} ease');
				border: 0;
				border-radius: 0.5em;
				color: _palette(fg-light);
				display: block;
				font-size: 1em;
				font-weight: _font(weight-thin);
				letter-spacing: _font(kerning-alt);
				line-height: 1.25;
				outline: 0;
				padding: 1.35em 0;
				position: relative;
				text-decoration: none;
				width: 50%;
				// text-transform: uppercase;

				&:before,
				&:after {
					border-radius: 0.2em;
					bottom: 0;
					content: '';
					height: 0.2em;
					position: absolute;
					left: 0;
					width: 100%;
				}

				&:before {
					background: lighten(_palette(bg), 5);
					width: 50%;
				}

				&:after {
					@include vendor('background-image', 'linear-gradient(to right, #{_palette(accent4)}, #{_palette(accent5)})');
					@include vendor('transition', 'max-width #{_duration(transition)} ease');
					max-width: 0;
				}

				&:hover {
					color: _palette(fg);
				}

				&.active {
					color: _palette(fg-bold);
					width: 75%;

					&:after {
						max-width: 50%;
					}
				}
			}
		}

		body.is-loading & {
			> .inner {
				opacity: 0;
			}

			nav {
				ul {
					li {
						@include vendor('transform', 'translateY(2em)');
						opacity: 0;
					}
				}
			}
		}

		@include breakpoint(large) {
			height: _size(sidebar-height);
			left: 0;
			line-height: _size(sidebar-height);
			overflow: hidden;
			padding: 0;
			text-align: center;
			top: 0;
			width: 100%;

			> .inner {
				@include vendor('flex-direction', 'row');
				@include vendor('align-items', 'stretch');
				height: inherit;
				line-height: inherit;
				justify-content: flex-start;
				padding-left: 2em;
			}

			nav {
				height: inherit;
				line-height: inherit;

				ul {
					@include vendor('display', 'flex');
					height: inherit;
					line-height: inherit;
					margin: 0;

					li {
						display: block;
						height: inherit;
						line-height: inherit;
						margin: 0 0 0 2em;
						padding: 0;

						&:last-child {
							margin-top: 0;
							// margin-left: 10em;
							justify-content: flex-end;
						}
					}
				}

				a {
					height: inherit;
					line-height: inherit;
					padding: 0;

					&:after {
						background-image: none;
						background-color: _palette(accent3);
					}
				}
			}
		}

		@include breakpoint(small) {
			display: none;
		}
	}