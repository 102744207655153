/* Image */

	.image {
		border: 0;
		display: inline-block;
		position: relative;
		pointer-events: visible;
		img {
			display: block;
		}

		&.left,
		&.right,
		&.center-fit {
			max-width: 25%;
			img {
				width: 100%;
			}

			&-c {
				max-width: 40%;
				img {
					width: 100%;
				}
			}
		}

		&.left {
			// float: left;
			margin: 2.5em;
			top: 0.25em;
			@include breakpoint(medium) {
				margin: 0 0 _size(element-margin) 0;
				max-width: 100%;
			}
	
			&-c {
				float: left;
				margin: 2.5em;
				top: 0.25em;
				@include breakpoint(medium) {
					margin: 0 0 _size(element-margin) 0;
					max-width: 100%;
				}
			}
		}

		&.right {
			margin: 2.5em;
			top: 0.25em;
			@include breakpoint(medium) {
				margin: 0 0 _size(element-margin) 0;
				max-width: 100%;
			}

			&-c {
				float: right;
				margin: 2.5em;
				top: 0.25em;
				@include breakpoint(medium) {
					margin: 0 0 _size(element-margin) 0;
					max-width: 100%;
				}
			}
		}

		&.center-fit {
			margin: 2.5em;
			top: 0.25em;
			@include breakpoint(medium) {
				margin: 0 0 _size(element-margin) 0;
				max-width: 100%;
			}

			&-c {
				max-width: 50%;
				margin: 2.5em;
				top: 0.25em;
				@include breakpoint(medium) {
					margin: 0 0 _size(element-margin) 0;
					max-width: 100%;
				}
			}
		}

		&.center {
			display: block;
			margin: 0 0 _size(element-margin) 0;
			padding-top: 2em;
			padding-bottom: 1em;
			justify-content: center;
			width: 75%;

			img {
				width: 100%;
			}
			@include breakpoint(medium) {
				margin: 0 0 _size(element-margin) 0;
				padding-top: 0;
				padding-bottom: 0;
				width: 100%;
				@include breakpoint(medium) {
					margin: 0 0 _size(element-margin) 0;
					max-width: 100%;
				}
			}
		}

		&.fit {
			float:left;
			padding: 0;
			margin: 0;
			width: 100%;
			img {
				width: 100%;
			}
		}

		&.fit-left {
			float: left;
			padding: 0;
			margin: 0;
			width: 50%;

			img {
				width: 100%;
			}
		}

		&.fit-right {
			float: right;
			padding: 0;
			margin: 0;
			width: 50%;

			img {
				width: 100%;
			}
		}

		
		&.egerie {
			position: fixed;
			left: 50%;
			top: 7%;
			bottom: 0;
			img {
				width: 100%;
			}
			@include breakpoint(medium) {
				left: 40%;
			}
			@include breakpoint(small) {
				margin: _size(element-margin);
				position: relative;
				max-width: 100%;
				left: 0;
			}
		}

		&.wax {
			width: 100%;
			img {
				width: 100%;
			}
		}

		&.article {
			margin: 0 0 _size(element-margin) 0;
			width: 150%;
			margin-top: -50%;
			margin-left: 40%;
			img {
				width: 100%;
			}
			// @include breakpoint(xlarge) {
			// 	width: 125%;
			// 	margin-top: -45%;
			// 	margin-left: 40%;
			// }
			@include breakpoint(xlarge) {
				width: 175%;
				margin-top: -45%;
				margin-left: 25%;
			}
			@include breakpoint(large) {
				width: 150%;
				margin-top: -45%;
				margin-left: 25%;
			}
			@include breakpoint(medium) {
				width: 100%;
				margin-top: 0;
				margin-left: 0;
			}
			@include breakpoint(small) {
				width: 100%;
			}
		}

		&.main {
			display: block;
			margin: 0 0 (_size(element-margin) * 1.5) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}
	}