/* Basic */

	html {

		scrollbar-color: transparent transparent;
		overflow-y: scroll;
		scrollbar-width: thin;

		//Chrome 
		::-webkit-scrollbar {
			height: 3px;
			width: 5px;
			background: transparent;
		}
		::-webkit-scrollbar-thumb {
			background: _palette(bg);
		}
		::-webkit-scrollbar-corner {
			background: _palette(bg);
		}

		//IE
		scrollbar-face-color: transparent;
		scrollbar-shadow-color: transparent;
		scrollbar-highlight-color: transparent;
		scrollbar-3dlight-color: transparent;
		scrollbar-darkshadow-color: transparent;
		scrollbar-track-color: transparent;
		scrollbar-arrow-color: transparent;
	}

	// MSIE: Required for IEMobile.
		@-ms-viewport {
			width: device-width;
		}

// MSIE: Prevents scrollbar from overlapping content.
	body {
		-ms-overflow-style: scrollbar;
	}

	// Ensures page width is always >=320px.
		@include breakpoint(xsmall) {
			html, body {
				min-width: 320px;
			}
		}

	body {
		background: _palette(bg);
		padding-bottom: 50px;

		// Snipcart override
			.snip-layout {
				z-index: 90000;

				.snip-product__name, .snip-header__total-label, .snip-btn--highlight, .snip-btn--highlight:hover, 
				.snip-step__label, .snip-form__label, .snip-btn--right, .snip-btn--left, .snip-product__important, 
				#snipcart-title, .snip-product__name, .snip-form__label, .snip-table__cell--highlight, #snipcart-amount, 
				.snip-product__empty, .snip-form__error, span {
					font-family: _font(family);
				}

				.snip-product__empty, .snip-header__title {
					text-transform: none;
				}

				#snipcart-amount {
					font-size: 20px;
				}

				.snip-btn--right, .snip-btn--left, .snip-form__error {
					font-weight: _font(weight);
					border-radius: 0;
				}

				.snip-product__remove, .snip-footer__highlight, .snip-form__label, #snipcart-show-discount-box, 
				#snipcart-title, .snip-product__name, .snip-form__label, #snipcart-amount, .snip-ico--close {
					color: _palette("bg");
				}

				#snipcart-header, .snip-footer, .snip-header__total {
					background-color: _palette("fg-bold");
				}

				.snip-footer__copyright {
					display: none;
				}

				.snip-quantity-trigger__btn, .snip-quantity-trigger__btn:hover, .snip-btn--highlight, .snip-btn--highlight:hover {
					background-color: _palette("bg");
					color: _palette("fg-bold");
					font-weight: _font(weight);
				}

				.snip-table td {
					border-bottom:  1px solid _palette("fg-bold");
				}
			}

			.snip-header__total-label {
				color: _palette(fg-bold);
			}

		.home-page {
			display: flex;
			justify-content: center; 
			flex-flow: nowrap;
			> * {
				flex: 1 100%;
			}
		}
		// Prevents animation/transition "flicker" on page load.
		// Automatically added/removed by js/main.js.
			&.is-loading {
				*, *:before, *:after {
					@include vendor('animation', 'none !important');
					@include vendor('transition', 'none !important');
				}
			}

	}

	.arrow {
		color: _palette(fg-bold);
		font-size: 3.75em;
		pointer-events: visible;
		@include breakpoint(small) {
			font-size: 2.25em;
		}
		> a {
			margin: 0;
			border: 0;
		}
		&.left {
			float: left;
			margin-left: 5%;
			margin-top: -10%;
			@include breakpoint(small) {
				margin-left: .5em;
				// left: 0;
			}
		}
		&.right {
			float: right;
			margin-right: 5%;
			margin-top: -10%;
			@include breakpoint(small) {
				margin-right: .5em;
			}
			&.first {
				margin-top: -22%;
			}
		}
		&.down {
			pointer-events: visible;
			position: absolute;
			left: 46%;
			@include breakpoint(xsmall) {
				left: 45%;
			}			

			&.first {
				top: 83%;
			}

			&.after {
				top: 83%;
			}
		}
	}

	.home {
		position: absolute;
		z-index: 2000000;
		top: 59%;
		left: 43%;
		-webkit-animation: 1s ease 2s normal forwards 1 home;
		animation: 1s ease 2s normal forwards 1 home;
		visibility: hidden;
		> a {
			border: 0;
			&:hover {
				color: _palette(fg);
			}
		}
		@include breakpoint(large) {
			top: 58%;
			left: 40%;
			font-size: 2.5em;
		}
		@include breakpoint(medium) {
			// top: 57%;
			// left: 43%;
			font-size: 1.8em;
		}
		@include breakpoint(small) {
			top: 55%;
			left: 40%;
			font-size: 1.25em;
		}
		@include breakpoint(xsmall) {
			top: 55%;
			left: 38%;
			font-size: 1em;
		}
	}

	@keyframes home{
		from { opacity:0; }
		to { opacity:1; visibility: visible; }
	}
	
	@-webkit-keyframes home{
		from { opacity:0; }
		to { opacity:1; visibility: visible; }
	}

	#video-screen {
		position: fixed;
		right: 0;
		bottom: 0;
		width: 100%; 
		height: auto;
		@include breakpoint(medium) {
			display: none;
		}
	}

	#video-mobile {
		display: none;
		@include breakpoint(medium) {
			display: initial;
			position: fixed;
			right: 0;
			bottom: 0;
			width: 100%; 
			height: 100%;
		}
		@include breakpoint(small) {
			position: fixed;
			right: 0;
			bottom: 0;
			width: 100%; 
			height: 100%;
			// width: 125%; 
			// transform: translate(11%, -5%);
		}
		// @include breakpoint(xsmall) {
		// 	width: 175%; 
		// 	transform: translate(21%, -5%);
		// }
	}

// animation container
  .logo-anim {
	margin-top: -150px;
	width: 90%;
	z-index: 5;
	pointer-events: none;
	@include breakpoint(small) {
		width: 80%;
		margin-top: 45%;
	}
  }

	.black {
		color: _palette(bg);
	}

	.bg-white {
		background: _palette(fg-bold);
	}

	.bg-gray {
		background: _palette(accent4);
	}