/* Wrapper (main) */

	.size-array {
		font-size:0.8em;
		pointer-events:auto;
		&:hover{
			color:black;
		}
	}

	#wrapper-video {
		display: flex;
		justify-content: center;
		margin-top: 10em;
		> video {
			width: 50%;
			height: auto;
		}
	}

	#wrapper {
		pointer-events: none;
		#sidebar + & {
			margin-left: _size(sidebar-width);

			@include breakpoint(large) {
				margin-left: 0;
				padding-top: _size(sidebar-height);
			}

			@include breakpoint(small) {
				padding-top: 0;
			}
		}

		#header + & {
			> .wrapper {
				> .inner {
					margin: 0 auto;
						text-align: left;
						display: flex;
						justify-content: center;
						align-items: baseline;
						flex-flow: row wrap;
						> * {
							flex: 1 60%;
						}

					&.about {
						margin-top: 150px;
						width: 60%;
						@include breakpoint(large) {
							// font-size: 2.15em;
							// margin-top: -0.5em;
							// margin-left: 200px;
						}
						@include breakpoint(medium) {
							margin-top: 50px;
							width: 80%;
							// font-size: 1.55em;
							// margin-top: -1.5em;
							// left: 31.5%;
						}
					
					}
				}
			}
		}
		#sidebar_memo + & {
			margin-left: _size(sidebar-width);

			@include breakpoint(large) {
				margin-left: 0;
				padding-top: _size(sidebar-height);
			}

			@include breakpoint(small) {
				padding-top: 0;
			}
		}
	}